// extracted by mini-css-extract-plugin
export var blogPostCategories = "CustomBlogPostSection__blogPostCategories__jKV_y";
export var blogPostsContainer = "CustomBlogPostSection__blogPostsContainer__hXv8S";
export var categoriesList = "CustomBlogPostSection__categoriesList__kYAXI";
export var categoryImagePlaceholder = "CustomBlogPostSection__categoryImagePlaceholder__knqxZ";
export var categoryList = "CustomBlogPostSection__categoryList___jDPw";
export var categoryTitle = "CustomBlogPostSection__categoryTitle__AoOJl";
export var column = "CustomBlogPostSection__column__QbQxx";
export var header = "CustomBlogPostSection__header__UFEY9";
export var morePosts = "CustomBlogPostSection__morePosts__agTU9";
export var postDate = "CustomBlogPostSection__postDate__OpBDx";
export var postTitle = "CustomBlogPostSection__postTitle__PlNZI";
export var row = "CustomBlogPostSection__row__qNF_U";
export var section = "CustomBlogPostSection__section__TDBRe";
export var sidebar = "CustomBlogPostSection__sidebar__tyB16";
export var trendingPosts = "CustomBlogPostSection__trendingPosts__JB_fD";